.siteHeader {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
}

.siteHeader_title {
    font-size: 1.25em;
    padding: 1em;
}

@media (min-width:320px)  { 
    /* smartphones, iPhone, portrait 480x320 phones */ 
    .siteHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 50px;
        padding: 0 var(--margin);
        border-bottom: 1px solid var(--c-border);
    }
}
@media (min-width:961px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    /* body {
        background-image: url('./resources/images/home.jpg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    } */

    .siteHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50px;
        height: 100%;
        border-right: 1px solid var(--c-border);
    }
    .siteHeader_title {
        position: absolute;
        left: 0;
        top: 50%;
        transform-origin: 0% 100%;
        transform: translate(34px, 18px) rotate(-90deg);
        white-space: nowrap;
    }
    .siteHeader_menuBtn {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
        display: flex; 
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(34,34,34,.25);
    }

}